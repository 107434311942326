import React from 'react'
import './TermsAndCondition.css'
const TermsAndCondition = () => {
  return (
    <div>
        <h1>Terms & Condition</h1>
    </div>
  )
}

export default TermsAndCondition
    