
import Routespath from './routescomp/Routespath';

function App() {
  return (
    <>
    
    <Routespath/>
    
    
    {/* <ChatInbox /> */}
    {/* <Chatbot /> */}
    {/* <ChatCard /> */}
    
    </>
  );
}

export default App;
